<template>
  <div class='contact-info'>
    <h3>
      Contact Info
      <edit-icon-btn  @click='$refs[`modal`].show()' />
    </h3>
    <div>{{ order_name }}</div>
    <div>{{ order.billing.company }}</div>
    <div>{{ order.billing.email }}</div>
    <div>{{ order.billing.phone }}<template v-if='order.billing.extension'> Ext. {{ order.billing.extension }}</template></div>

    <b-modal
      ref='modal'
      class='edit-contact-info-modal'
      centered
      title='Contact Info'
      size=md
      ok-title='Continue'
      ok-variant='primary'
      cancel-variant='medium'
      @ok='update_address'
      @show='reset_address'
      :busy='busy'
      >

      <b-row v-if='pending_address' class='form-group-row'>
        <b-col cols=6>
          <b-form-group
            label='Name'
            label-for='contact-name'
            >
              <b-form-input
                id='contact-name'
                v-model='pending_name'
                required
                />
          </b-form-group>
        </b-col>

        <b-col cols=6>
          <b-form-group
            label='Company'
            label-for='contact-company'
            >
              <b-form-input
                id='contact-company'
                v-model='pending_address.company'
                />
          </b-form-group>
        </b-col>

        <b-col cols=12>
          <b-form-group
            label='Email'
            label-for='contact-email'
            >
              <b-form-input
                id='contact-email'
                v-model='pending_address.email'
                />
          </b-form-group>
        </b-col>

        <b-col cols=6>
          <b-form-group
            label='Phone'
            label-for='contact-phone'
            >
              <b-form-input
                id='contact-phone'
                :value='pending_address.phone'
                v-imask="phone_mask"
                @accept="acceptPhoneMask"
                />
          </b-form-group>
        </b-col>

        <b-col cols=6>
          <b-form-group
            label='Extension'
            label-for='contact-phone-ext'
            >
              <b-form-input
                id='contact-phone-ext'
                :value='pending_address.extension'
                v-imask="numeric_mask"
                @accept="acceptNumericMask"
                />
          </b-form-group>
        </b-col>

      </b-row>

    </b-modal>

  </div>
</template>

<script>
import _ from 'lodash';
import axios from '@/axios';
import { mapState, mapMutations } from 'vuex';
import { IMaskDirective } from 'vue-imask';

// this component is basically a duplicate of the edit-address componenet but displays and allows editing of different fields

export default {
  name: 'ContactInfo',
  directives: {
    imask: IMaskDirective
  },
  data () {
    return {
      pending_address: null,
      busy: false,
      phone_mask: {
        mask: '(000) 000-0000',
        lazy: false,
      },
      numeric_mask: {
        mask: /^\d*$/,
        lazy: false,
      },
    }
  },
  computed: {

    ...mapState( 'order_details', [
      'order',
    ]),

    order_name () {
      return [ this.order.billing.first_name, this.order.billing.last_name ].filter( x => x ).join( ' ' );
    },

    pending_name: {
      get () {
        return [ this.pending_address.first_name, this.pending_address.last_name ].filter( x => x ).join( ' ' );
      },
      set ( value ) {
        let parts = value.split( ' ' );
        this.pending_address.first_name = parts.shift() ?? '';
        this.pending_address.last_name = parts.join( ' ' );
      }
    }

  },
  methods: {

    ...mapMutations( 'order_details', [
      'set_order_prop',
    ]),

    reset_address () {
      this.pending_address = _.cloneDeep( this.order.billing );
      this.pending_name = this.order_name;
    },

    update_address ( e ) {
      e.preventDefault();
      this.busy = true;
      const busy_id = this.$jjToast({
        type: 'busy',
        title: 'Saving',
        content: 'Saving contact info',
      });
      axios.put( `orders/${ this.order.id }/address/billing`, { address: this.pending_address })
        .then( () => {
          this.set_order_prop({
            prop: 'billing',
            value: this.pending_address,
          });
          this.$refs['modal'].hide();
          this.$jjToast({
            type: 'success',
            title: 'Updated',
            content: 'Saved contact info',
          });
        })
        .catch( () => {
          this.$jjToast({
            type: 'error',
            title: 'Error',
            content: 'Failed to save contact info',
          });
        })
        .finally( () => {
          this.busy = false;
          this.$bvToast.hide( busy_id );
        });
    },

    acceptPhoneMask (e) {
      const maskRef = e.detail;
      console.log( maskRef.value );
      this.pending_address.phone = maskRef.value;
      return;
    },

    acceptNumericMask (e) {
      const maskRef = e.detail;
      this.pending_address.extension = maskRef.value;
      return;
    },

  }
}
</script>