<template>
  <tr>
    <th>
      <span>Discount</span>
      <edit-icon-btn  @click='$refs[`modal`].show()' />
    </th>
    <td>
      <span>{{ saved_discount_value | money_format }}</span>
      <b-modal
        ref='modal'
        class='edit-discount-modal'
        centered
        title='Set Order Discount'
        size=md
        ok-title='Continue'
        ok-variant='primary'
        cancel-variant='medium'
        @ok='save_value'
        @show='reset_value'
        :busy='busy'
        >
        <b-input-group append="%">
          <FixedDecimalNumberInput id='order-discount' v-model='pending_value' />
        </b-input-group>
      </b-modal>
    </td>
  </tr>
</template>

<script>
import _ from 'lodash';
import axios from '@/axios';
import { mapState, mapMutations } from 'vuex';
import FixedDecimalNumberInput from '@/components/fixed-decimal-number-input';

export default {
  name: 'OrderDiscount',
  components: {
    FixedDecimalNumberInput,
  },
  data () {
    return {
      busy: false,
      pending_value: 0,
    }
  },
  computed: {

    ...mapState( 'order_details', [
      'order',
    ]),

    saved_value () {
      return _.get( this.order, 'discount_percentage', 0 );
    },
    
    saved_discount_value () {
      return _.get( this.order, 'discount_total', 0 );
    },

  },
  methods: {

    ...mapMutations( 'order_details', [
      'set_order_prop',
    ]),

    reset_value () {
      this.pending_value = this.saved_value;
    },

    save_value ( e ) {
      e.preventDefault();
      this.busy = true;
      const busy_id = this.$jjToast({
        type: 'busy',
        title: 'Saving',
        content: 'Saving order discount',
      });
      axios.put( `orders/${ this.order.id }/discount`, { value: this.pending_value })
        .then( response => {
          this.set_order_prop({
            prop: 'total',
            value: response.data.order.total,
          });
          this.set_order_prop({
            prop: 'total_tax',
            value: response.data.order.total_tax,
          });
          this.set_order_prop({
            prop: 'discount_total',
            value: response.data.order.discount_total,
          });
          this.set_order_prop({
            prop: 'discount_percentage',
            value: response.data.order.discount_percentage,
          });
          this.$refs['modal'].hide();
          this.$jjToast({
            type: 'success',
            title: 'Updated',
            content: 'Saved order discount',
          });
        })
        .catch( () => {
          this.$jjToast({
            type: 'error',
            title: 'Error',
            content: 'Failed to save order discount',
          });
        })
        .finally( () => {
          this.busy = false;
          this.$bvToast.hide( busy_id );
        });
    }

  }
}
</script>