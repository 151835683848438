<template>
  <div class='number-of-orders'>
    <h3>
        Total # of Orders
        <edit-icon-btn @click='$refs[`modal`].show()' />
    </h3>
    <div>{{ order.total_num_orders }}</div>

    <b-modal
      ref='modal'
      :class='`edit-num-orders-modal`'
      centered
      :title='`Edit Total # of Orders`'
      size=md
      ok-title='Continue'
      ok-variant='primary'
      cancel-variant='medium'
      @ok='save_value'
      @show='reset_value'
      :busy='busy'
      >
      <b-row class='form-group-row'>
        <b-col cols=12>
          <b-form-group
            label='Total # of Orders'
            :label-for='`total-num-orders`'
            >
              <b-form-input
                :id='`total-num-orders`'
                v-model='pending_value'
                />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>

  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';

import { mapState, mapMutations } from 'vuex';

export default {
  name: 'EditNumerOfOrders',
  data () {
    return {
      pending_value: null,
      busy: false,
    }
  },
  computed: {

    ...mapState( 'order_details', [
      'order'
    ]),

    saved_value () {
      return _.get( this.order, 'total_num_orders', '' );
    },

  },
  methods: {

    ...mapMutations( 'order_details', [
      'set_order_prop',
    ]),

    reset_value () {
      this.pending_value = this.saved_value;
    },

    save_value ( e ) {
      e.preventDefault();
      this.busy = true;
      const busy_id = this.$jjToast({
        type: 'busy',
        title: 'Saving',
        content: 'Saving total number of orders',
      });
      axios.put( `orders/${ this.order.id }/total-num-orders`, { value: this.pending_value })
        .then( response => {
          this.set_order_prop({
            prop: 'total_num_orders',
            value: response.data.total_num_orders,
          });
          this.$refs['modal'].hide();
          this.$jjToast({
            type: 'success',
            title: 'Updated',
            content: 'Saved total number of orders',
          });
        })
        .catch( () => {
          this.$jjToast({
            type: 'error',
            title: 'Error',
            content: 'Failed to save total number of orders',
          });
        })
        .finally( () => {
          this.busy = false;
          this.$bvToast.hide( busy_id );
        });
    }

  },
}
</script>