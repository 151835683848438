<template>
  <div class='order-items'>

    <b-table
      :fields='fields'
      :items='items'
      per-page=0
      head-variant=dark
      :tbody-tr-class='backorder_class'
      >

      <template #cell(product_name)="data">
        <OrderItemName :item='data.item' @item-updated='args => item_updated( data.item.id, args )' />
      </template>

      <template #cell(quantity)="data">
        <OrderItemQuantity :qty='parseInt( data.item.quantity )' @input='value => item_updated( data.item.id, { quantity: parseInt( value ) } )'/>
      </template>

      <template #cell(per_unit)="data">
        <FixedDecimalNumberInput decimals=2 :value='data.item.per_unit' @input='value => item_updated( data.item.id, { per_unit: Math.max( value ? parseFloat( value ) : 0, 0 ) } )' />
      </template>

      <template #cell(row-actions)="data">
        <b-row class='row-actions'>
          <b-col cols='auto'>
            <b-button class='duplicate-btn' variant='link' @click.prevent='duplicate_item( data.item.id )'>
              <span class='sr-only'>Duplicate</span>
              <font-awesome-icon icon="fa-regular fa-clone" />
            </b-button>
          </b-col>
          <b-col cols='auto'>
            <b-button class='delete-btn' variant='link' @click.prevent='delete_item( data.item.id )'>
              <span class='sr-only'>Delete</span>
              <font-awesome-icon icon="fa-regular fa-xmark" />
            </b-button>
          </b-col>
        </b-row>
      </template>

    </b-table>

    <div class='actions'>
      <b-row>
        <b-col cols='auto'>
          <SaveItemsButton />
        </b-col>
        <b-col cols='auto'>
          <AddOrderItems @added='id => item_updated( id, { quantity: 1 } )' />
        </b-col>
      </b-row>
    </div>

  </div>
</template>

<script>
import _ from 'lodash';
import Big from 'big.js';
import { money_format } from '@/functions/formatting';
import OrderItemName from './table-columns/item-name';
import OrderItemQuantity from './table-columns/quantity';
import FixedDecimalNumberInput from '@/components/fixed-decimal-number-input';
import AddOrderItems from './add-items';
import SaveItemsButton from './save-items-btn';

import { mapState, mapMutations } from 'vuex';

export default {
  name: 'OrderItems',
  components: {
    OrderItemName,
    OrderItemQuantity,
    FixedDecimalNumberInput,
    AddOrderItems,
    SaveItemsButton,
  },
  computed: {

    ...mapState( 'order_details', [
      'order',
    ]),

    items () {
      return Object.values( this.order.line_items );
    },

  },
  data () {
    return {
      fields: [
        {
          key: 'product_name',
          label: 'Product',
        },
        // {
        //   key: 'sku',
        //   label: 'SKU Number',
        //   formatter: 'get_sku',
        // },
        {
          key: 'quantity',
          label: 'Quantity',
        },
        {
          key: 'per_unit',
          label: 'Unit Price',
        },
        {
          key: 'subtotal',
          label: 'Amount',
          formatter: 'item_subtotal',
        },
        {
          key: 'row-actions',
          label: '',
        }
      ],
    }
  },
  methods: {

    ...mapMutations( 'order_details', [
      'set_order_prop',
      'unset_order_prop',
    ]),


    item_subtotal ( value, key, item ) {
      return money_format( Big( item.per_unit ).times( item.quantity ) );
    },


    // get_sku ( value, key, item ) {
    //   if ( item.variations ?? null ) {
    //     const variation = item.variations.find( x => x.id == item.variation_id );
    //     return ( variation && ( variation.sku ?? null ) ) ? variation.sku : '--';
    //   }
    //   return value;
    // },


    delete_item ( id ) {
      this.unset_order_prop( `line_items.${ id }` );
    },


    duplicate_item ( id ) {
      let item_clone = _.cloneDeep( this.order.line_items[ id ] );
      item_clone.id = _.uniqueId( 'new-item' );
      this.set_order_prop({
        prop: `line_items.${ item_clone.id }`,
        value: item_clone,
      });
    },


    item_updated ( item_id, args ) {
      let item_clone = _.cloneDeep( this.order.line_items[ item_id ] );
      Object.entries( args ).forEach( entry => {
        const [ key, value ] = entry;
        item_clone[ key ] = value;
      });

      item_clone.per_unit = Big( item_clone.per_unit ).toNumber();
      item_clone.subtotal = Big( item_clone.per_unit ).times( item_clone.quantity ).toNumber();
      item_clone.subtotal_tax = Big( item_clone.subtotal ).times( item_clone.tax_rate ).div( 100 ).toNumber();
      item_clone.total = item_clone.subtotal;
      item_clone.tax = item_clone.subtotal_tax;

      this.set_order_prop({
        prop: `line_items.${ item_id }`,
        value: item_clone,
      });
    },


    backorder_class ( item, type ) {
      if ( item && type === 'row' && item.is_backordered ) {
        return 'backordered';
      }
      return null;
    },


  },
}
</script>


<style lang='scss' scoped>
.table {
  margin: 0;
  table-layout: fixed;
  & /deep/ th,
  & /deep/ td {
    vertical-align: middle;
    // int inputs
    &:nth-child( 2 ),
    &:nth-child( 3 ) {
      width: 18%;
    }
    // subtotal -- large enough for $xxxxx.xx
    &:nth-child( 4 ) {
      width: 110px;
    }
    // delete column
    &:last-child {
      width: 115px;
      & > .row {
        flex-wrap: nowrap;
      }
    }
  }
  & /deep/ tbody{
    border-bottom: 1px solid var( --bs-border-color );
  }
}
.actions {
  margin-top: 1rem;
  .row {
    justify-content: flex-end;
    --gutter-x: 0.5rem;
    --gutter-y: 0.5rem;
  }
  & /deep/ button {
    font-weight: bold;
  }
}
.row-actions {
  button {
    line-height: 1;
    border-radius: 50%;
    display: block;
    padding: 0;
    font-size: 1.25em;
    width: 1.75em;
    height: 1.75em;
    color: var( --icon-color );
    background-color: var( --white );
    transition: .3s ease;
    &:hover,
    &:focus {
      color: var( --white );
      background-color: var( --icon-color );
    }
    position: relative;
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate( -50%, -50% );
    }
  }
}
.delete-btn {
  --icon-color: var( --danger );
}
.duplicate-btn {
  --icon-color: var( --blue );
}
</style>