<template>
  <b-button variant=link @click='create_new' :disabled='busy'>
    <font-awesome-icon v-if='busy' icon='fa-solid fa-spinner' class='fa-spin' />
    <font-awesome-icon v-else icon='fa-light fa-receipt'/>
    <span>New Order</span>
  </b-button>
</template>


<script>
import { mapActions } from 'vuex';

export default {
  name: 'CreateNewOrderLink',
  props: {
    action_block: {
      required: false,
      type: Function
    }
  },
  data () {
    return{
      busy: false,
    }
  },
  methods: {
    ...mapActions( 'orders', {
      create_new_order: 'create_new'
    }),
    create_new () {
      if ( !this.busy ) {
        if ( ( this.action_block ?? null ) && this.action_block() ) {
          return;
        }
        this.busy = true;
        const busy_id = this.$jjToast({
          type: 'busy',
          title: 'Loading',
          content: 'Creating new order',
        });
        this.create_new_order()
          .catch( () => {
            this.$jjToast({
              type: 'error',
              title: 'Error',
              content: `Failed to create new order`,
            });
          })
          .finally( () => {
            this.busy = false;
            this.$bvToast.hide( busy_id );
          });
      }
    }
  }
}
</script>