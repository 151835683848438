import _ from 'lodash';

export function create_new_order_item ( product ) {
  const uniqid = _.uniqueId( 'new-item' );
  return {
    id: uniqid,
    product_id: product.id,
    variation_id: 0,
    sku: product.sku,
    name: product.name,
    product_name: product.name,
    attributes: product.attributes ?? [],
    meta_data: product.attributes.map( x => {
      return {
        key: x.key,
        value: ''
      };
    }),
    variations: product.variations ?? null,
    per_unit: product.price ? product.price : 0,
    quantity: 0,
    subtotal: 0,
    subtotal_tax: 0,
    total: 0,
    total_tax: 0,
    tax_rate: product.tax_rate,
  };
}