<template>
  <div class='add-order-item'>
    <b-button variant='primary' @click='$refs[`modal`].show()'>
      <font-awesome-icon icon='fa-solid fa-plus' />
      Add Product
    </b-button>

    <b-modal
      ref='modal'
      class='add-order-item-modal'
      centered
      title='Add Product to Order'
      size=md
      ok-title='Add'
      ok-variant='primary'
      cancel-variant='medium'
      @ok='add_items'
      @show='reset'
      :busy='busy'
      >
      <vue-multiselect
        placeholder='Select products'
        id='product_selector'
        :multiple='true'
        :allow-empty='true'
        :searchable='true'
        trackBy='index'
        label='name'
        v-model='selected'
        :options='options'
        :loading='search_busy'
        @search-change='search_products'
        :internal-search='false'
        :optionHeight="200"
        :showLabels='false'
        :close-on-select='false'
        :clear-on-select='false'
        @select='on_select'
        @input='input'
        >
        <span slot='noResult'>No products found matching search.</span>
      </vue-multiselect>
    </b-modal>
  </div>
</template>

<script>
import axios from '@/axios';
import _ from 'lodash';
import { create_new_order_item } from '@/functions/create-new-order-item';

import { mapState, mapMutations } from 'vuex';

export default {
  name: 'AddOrderItems',
  data() {
    return {
      abort_controller: null,
      search_busy: false,
      busy: false,
      selected: [],
      options: [],
    }
  },
  computed: {
    ...mapState( 'order_details', [
      'order'
    ]),
  },
  methods: {

    ...mapMutations( 'order_details', [
      'set_order_prop',
    ]),


    reset () {
      this.selected = [];
    },


    input () {
      this.selected[ this.selected.length - 1 ].index = Date.now();
    },
    
    on_select () {
      this.options = _.cloneDeep( this.options );
    },


    add_items () {
      this.selected.forEach( product => {
        const new_item = create_new_order_item( product );
        this.set_order_prop({
          prop: `line_items.${ new_item.id }`,
          value: new_item,
        });
        this.$emit( 'added', new_item.id );
      });
    },


    search_products: _.debounce( function ( search ) {
      if ( this.abort_controller ) {
        this.abort_controller.abort();
      }
      this.abort_controller = new AbortController();
      this.search_busy = true;
      axios
        .get( `${ process.env.VUE_APP_BASE_SITE_URL }/wp-json/wc/v3/products`, {
          signal: this.abort_controller.signal,
          params: {
            jjprodsearch: true,
            search,
            per_page: 50,
            status: 'publish',
            order_id: this.order.id
            // exclude: this.selected.map( x => x.id ),
          }
        })
        .then( response => {
          this.options = response.data;
          this.search_busy = false;
        })
        .catch( e => {
          if ( _.get( e, 'constructor.name' ) != 'Cancel' ) {
            this.search_busy = false;
          }
        })
    }, 100 ),

  },
}
</script>