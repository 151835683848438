<template>
  <table class='order-totals'>
    <tbody>
      <tr>
        <th>Subtotal</th>
        <td>{{ subtotal | money_format }}</td>
      </tr>
      <tr is='OrderDiscount' />
      <tr>
        <th>Shipping</th>
        <td>{{ shipping | money_format }}</td>
      </tr>
      <tr>
        <th>Tax</th>
        <td>{{ tax | money_format }}</td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <th>Total</th>
        <td>{{ total | money_format }}</td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
import _ from 'lodash';
import Big from 'big.js';
import { mapState } from 'vuex';

import OrderDiscount from './discount';

export default {
  name: 'OrderTotals',
  components: {
    OrderDiscount,
  },
  computed: {

    ...mapState( 'order_details', [
      'order',
    ]),

    subtotal () {
      return Object.values( this.order.line_items ).map( x => x.subtotal ).reduce( ( carry, x ) => { return carry.plus( x ) }, Big( 0 ) ).toNumber();
    },

    tax () {
      // return Big(
      //   Object.values( this.order.line_items ).map( x => x.subtotal_tax ).reduce( ( carry, x ) => { return carry.plus( x ) }, Big( 0 ) ).toNumber() + ( this.order.shipping_total * ( this.order.shipping_tax / 100 ) )
      // );
      // return Object.values( this.order.line_items ).map( x => x.subtotal_tax ).reduce( ( carry, x ) => { return carry.plus( x ) }, Big( 0 ) ).toNumber();
      return Big( this.order.total_tax ).toNumber();
    },

    shipping () {
      return Object.values( this.order.shipping_lines ).reduce( ( carry, x ) => { return carry.plus( x.total ) }, Big( 0 ) ).toNumber();
    },

    total () {
      return Big( this.order.total ).toNumber();
    },

  },
}
</script>

<style lang="scss">
table.order-totals {
  margin-left: auto;
  tbody {
    font-size: 12px;
    th {
      font-weight: normal;
      color: var( --dark );
    }
  }
  tr:not(:first-child),
  tfoot tr {
    th, td {
      padding-top: 0.75em;
    }
  }
  th {
    padding-right: 1em;
  }
  td {
    min-width: 75px;
    text-align: right;
  }
  tfoot {
    font-weight: bold;
  }
}
</style>