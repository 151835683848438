<template>
  <div class='save-items-btn'>
    <b-button
      :variant='disabled ? `outline-medium` : `success`'
      :disabled='disabled'
      @click='save_items'
      >
      <font-awesome-icon v-if='busy' icon='fa-solid fa-spinner' class='fa-spin' />
      <font-awesome-icon v-else icon="fa-solid fa-floppy-disk" />
      Save Changes
    </b-button>

    <b-modal id="modalError" title="Product Misconfigured" ok-only>
    <p>
      Please select a valid variation for these product(s) to save:
      <ul v-for="e in errors" :key="e.id">
        <li>{{ e.name }}</li>
      </ul>
      
    </p>
  </b-modal>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'SaveItemsButton',
  data () {
    return {
      busy: false,
      errors: false,
    }
  },
  computed: {

    ...mapState( 'order_details', [
      'order',
      'item_changes_pending',
    ]),

    disabled () {
      return this.busy || !this.item_changes_pending;
    },

  },
  methods: {

    ...mapMutations( 'order_details', [
      'set_item_changes_pending',
      'set_order_prop',
    ]),

    save_items () {
      this.errors = [];

      for ( var i in this.order.line_items ) {
        if ( typeof this.order.line_items[i].variations !== 'undefined' && this.order.line_items[i].variations.length > 0 ) {
          if ( this.order.line_items[i].variation_id == 0 ) {
            this.errors.push( this.order.line_items[i] );
          }
        }
      }

      if ( this.errors.length ) {
        this.$bvModal.show('modalError');
        return;
      }

      this.busy = true;
      
      const busy_id = this.$jjToast({
        type: 'busy',
        title: 'Saving',
        content: 'Saving order items',
      });
      
      axios.put( `orders/${ this.order.id }/order_items`, { items: this.order.line_items } )
        .then( (response) => {
          this.set_item_changes_pending( false );
          this.set_order_prop({
            prop: 'total',
            value: response.data.order.total,
          });
          this.set_order_prop({
            prop: 'total_tax',
            value: response.data.order.total_tax,
          });
          this.set_order_prop({
            prop: 'discount_total',
            value: response.data.order.discount_total,
          });
          this.set_order_prop({
            prop: 'discount_percentage',
            value: response.data.order.discount_percentage,
          });

          this.$jjToast({
            type: 'success',
            title: 'Updated',
            content: 'Saved order items',
          });
        })
        .catch( () => {
          this.$jjToast({
            type: 'error',
            title: 'Error',
            content: 'Failed to save order items',
          });
        })
        .finally( () => {
          this.busy = false;
          this.$bvToast.hide( busy_id );
        });
    },

  },
}
</script>