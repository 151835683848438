<template>
  <div v-if='guest_customer' class='customer-details'>
    <h3>
      Account
      <edit-icon-btn  @click='$refs[`modal`].show()' />
    </h3>
    <template v-if='order.customer'>
      <div>{{ order.customer.name }}</div>
      <div v-if='order.customer.email'>{{ order.customer.email }}</div>
      <div v-if='order.customer.groups.length'>{{ order.customer.groups.join( ', ' ) }}</div>
    </template>
    <div v-else>None</div>

    <b-modal
      ref='modal'
      :class='`select-customer-modal`'
      centered
      title='Select Account'
      size=md
      ok-title='Continue'
      ok-variant='primary'
      cancel-variant='medium'
      @ok='save_customer'
      @show='reset'
      :busy='busy'
      >

      <vue-multiselect
        placeholder='Select an account'
        :multiple='false'
        :allow-empty='false'
        :searchable='true'
        :custom-label='append_customer_label'
        v-model='new_customer'
        :options='options'
        :loading='search_busy'
        @search-change='search_users'
        :internal-search='false'
        :showLabels='false'
        trackBy='id'
        >
        <span slot='noResult'>No accounts found matching search.</span>
      </vue-multiselect>

      <b-form-group label="Use account's stored data (overwrites order data)" v-slot='{ ariaDescribedby }'>
        <b-form-checkbox-group
          id='customer-data-overwrites'
          v-model='overwrites'
          :options='overwrite_options'
          :aria-describedby='ariaDescribedby'
          name='customer-data-overwrites'
          stacked
          :disabled='is_guest'
        />
      </b-form-group>

    </b-modal>

  </div>
</template>

<script>
import _ from 'lodash';
import axios from '@/axios';
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'CustomerDetails',
  data () {
    return {
      abort_controller: null,
      new_customer: null,
      is_search_result: false,
      search_options: [],
      search_busy: false,
      busy: false,
      selected_overwrites: [],
      overwrite_options: [
        {
          text: 'Contact Information',
          value: 'contact',
        },
        {
          text: 'Billing Address',
          value: 'billing',
        },
        {
          text: 'Shipping Address',
          value: 'shipping',
        },
      ],
    }
  },
  computed: {

    ...mapState( 'order_details', [
      'order',
    ]),

    ...mapState( 'orders', [
      'guest_customer',
    ]),

    options () {
      let options =  this.search_options;
      [ 'new_customer', 'guest_customer' ].forEach( prop => {
        if ( this[ prop ] && !options.some( x => x.id == this[ prop ].id ) ) {
          options.unshift( this[ prop ] );
        }
      });
      return options;
    },

    is_guest () {
      return !( this.new_customer && this.new_customer.id );
    },

    overwrites: {
      get () {
        return this.is_guest ? [] : this.selected_overwrites;
      },
      set ( value ) {
        this.selected_overwrites = value;
      }
    }

  },
  methods: {

    ...mapMutations( 'order_details', [
      'set_order_prop',
    ]),
    

    append_customer_label ( customer ) {
      return customer.email ? `${ customer.name } (${ customer.email })` : customer.name;
    },


    reset () {
      this.new_customer = this.order.customer;
      this.overwrites = this.overwrite_options.map( x => x.value );
    },


    search_users: _.debounce( function ( search ) {
      if ( this.abort_controller ) {
        this.abort_controller.abort();
      }
      this.abort_controller = new AbortController();
      this.is_search_result = Boolean( search );
      if ( !this.is_search_result ) {
        this.search_options = [];
        return;
      }
      this.search_busy = true;
      axios.get( `/customers/search/${ encodeURIComponent( search ) }`, {
          signal: this.abort_controller.signal,
        })
        .then( response => {
          this.search_options = response.data;
          this.search_busy = false;
        })
        .catch( e => {
          if ( _.get( e, 'constructor.name' ) != 'Cancel' ) {
            this.search_busy = false;
          }
        })
    }, 100 ),


    save_customer ( e ) {
      e.preventDefault();
      this.busy = true;
      const busy_id = this.$jjToast({
        type: 'busy',
        title: 'Saving',
        content: 'Saving customer',
      });
      axios
        .put( `orders/${ this.order.id }/customer`, {
          customer: this.new_customer ? this.new_customer.id : 0,
          overwrites: this.overwrites,
        })
        .then( response => {
          Object.entries( response.data ).forEach( entry => {
            const [ key, value ] = entry;
            this.set_order_prop({
              prop: key,
              value,
            })
          });
          this.$refs['modal'].hide();
          this.$jjToast({
            type: 'success',
            title: 'Updated',
            content: 'Saved customer',
          });
        })
        .catch( () => {
          this.$jjToast({
            type: 'erorr',
            title: 'Error',
            content: 'Failed to save customer',
          });
        })
        .finally( () => {
          this.busy = false;
          this.$bvToast.hide( busy_id );
        });
    }

  },
}
</script>


<style lang="scss" scoped>
fieldset {
  margin: 0.75em 0 0;
  /deep/ legend {
    padding-bottom: 0.5em;
  }
  /deep/ #customer-data-overwrites .custom-checkbox:not(:first-child) {
    margin-top: 0.25em;
  }
}
</style>