<template>
  <div class="order-deleter">
    <b-button variant='link' @click='$refs.modal.show()'>Delete Order</b-button>
    <b-modal
      ref='modal'
      class='delete-order-modal'
      centered
      title='Delete Order'
      size=md
      ok-title='Delete'
      ok-variant='danger'
      cancel-variant='medium'
      @ok='delete_order'
      :busy='busy'
      >
      <p>Are you sure you want to delete this order?</p>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import EventBus from '@/event-bus';
import axios from '@/axios';

export default {
  name: 'OrderDeleter',
  data () {
    return {
      busy: false,
    }
  },
  computed: {
    ...mapState( 'order_details', [
      'order'
    ]),
  },
  methods: {

    ...mapMutations( 'order_details', {
      set_order: 'order',
    }),

    delete_order ( e ) {
      e.preventDefault();
      this.busy = true;
      const busy_id = this.$jjToast({
        type: 'busy',
        title: 'Deleting',
        content: 'Deleting order',
      });
      axios.delete( `orders/${ this.order.id }` )
        .then( () => {
          this.$bvToast.hide( busy_id );
          this.$router.push({ name: 'orders' });
          EventBus.$emit( 'order-deleted', this.order.id );
        })
        .catch( () => {
          this.busy = false;
          this.$bvToast.hide( busy_id );
          this.$jjToast({
            type: 'error',
            title: 'Error',
            content: 'Failed to delete order',
          });
        });
    },

  }
}
</script>