<template>
  <div class='order-details'>
    <jj-loading v-if='loading || !statuses.length' centered />
    <template v-else>

      <b-row class='details-top-row'>
        <b-col cols=12 lg=auto class='order-info'>
          <h2>Order #{{ order.id }}</h2>
          <div>{{ order.date_created }}</div>
        </b-col>

        <b-col cols=auto>
          <b-row class='order-actions'>
            <b-col cols=auto>
              <CreateNewOrderLink :action_block='maybe_block_action'/>
            </b-col>
            <b-col cols=auto>
              <CloneOrderLink :action_block='maybe_block_action'/>
            </b-col>
            <b-col cols=auto>
              <OrderPdfExporter type='picking' :action_block='maybe_block_action'/>
            </b-col>
            <b-col cols=auto>
              <OrderPdfExporter type='packing' :action_block='maybe_block_action'/>
            </b-col>
            <b-col cols=auto>
              <OrderDeleter />
            </b-col>
          </b-row>
        </b-col>

      </b-row>


      <div class="py-4">
        <OrderStatusUpdater :action_block='maybe_block_action'/>
      </div>

    
      <div class='details-subsections'>
        <div :class="[order.customer_note != '' ? 'notes' : '', 'grid']">
          <div class="customer">
            <CustomerDetails />
            <ContactInfo />
          </div>

          <div class="addresses">
            <EditAddress type='billing' />
            <EditAddress type='shipping' />
            <EditAttention />
            <EditNumerOfOrders />
          </div>

          <div class="shipping">
            <ShippingDetails />
          </div>
          
          <div class="customer-note" v-if="order.customer_note != ''">
            <h3>Customer Note</h3>
            {{ order.customer_note }}
          </div>
        </div>
      </div>

        
      <OrderItems />
      <OrderTotals />

      <b-modal
        ref='action_blocked_modal'
        class='action-blocked-modal'
        centered
        title='Pending Changes'
        size=md
        ok-only
        >
        <p>You must save pending order item changes in order to proceed.</p>
      </b-modal>

    </template>
  </div>
</template>

<script>
import CustomerDetails from './customer-details';
import ContactInfo from './contact-info';
import EditAddress from './edit-address';
import EditAttention from './edit-attention';
import EditNumerOfOrders from './edit-num-orders';
import ShippingDetails from './shipping-details';
import CreateNewOrderLink from './new-order-link';
import CloneOrderLink from './clone-order-link';
import OrderPdfExporter from './pdf-exporter';
import OrderStatusUpdater from './status-updater';
import OrderDeleter from './delete';
import OrderItems from './items/index';
import OrderTotals from './totals';

import { mapState, mapMutations, mapActions } from 'vuex';

import EventBus from '@/event-bus';

export default {
  name: 'OrderDetails',
  components: {
    CustomerDetails,
    ContactInfo,
    EditAddress,
    EditAttention,
    EditNumerOfOrders,
    ShippingDetails,
    CreateNewOrderLink,
    CloneOrderLink,
    OrderPdfExporter,
    OrderStatusUpdater,
    OrderDeleter,
    OrderItems,
    OrderTotals,
  },
  computed: {

    ...mapState( 'orders', [
      'statuses'
    ]),

    ...mapState( 'order_details', [
      'loading',
      'order',
      'item_changes_pending',
    ]),

    order_status() {
      return this.statuses.find( x => this.order.status == x.key );
    },

  },
  methods: {

    ...mapActions( 'order_details', [
      'load_order',
    ]),


    ...mapMutations( 'order_details', [
      'set_order_prop',
    ]),


    receive_order_status_update ( data ) {
      if ( this.order.id == data.id ) {
        this.order.status = data.status;
        this.set_order_prop({
          prop: 'status',
          value: data.status,
        });
      }
    },


    // I don't think this function is used anymore
    receive_order_shipping_update ( shipping_line ) {
      this.order.shipping_lines[ shipping_line.id ] = shipping_line;
    },


    before_leave_guard ( next ) {
      if ( this.item_changes_pending && !window.confirm( 'Do you really want to leave? you have unsaved changes!' ) ) {
        return false;
      }
      next();
    },


    maybe_block_action () {
      if ( this.item_changes_pending ) {
        this.$refs.action_blocked_modal.show();
        return true;
      }
      return false;
    },


  },
  watch:{
    $route: {
      immediate: true,
      handler ( to ) {
        this.load_order( to.params.id );
      }
    }
  },
  created () {
    EventBus.$on( 'order-status-updated', this.receive_order_status_update );
  },
  beforeDestroy () {
    EventBus.$off( 'order-status-updated', this.receive_order_status_update );
  },
  beforeRouteUpdate ( to, from, next ) {
    this.before_leave_guard( next );
  },
  beforeRouteLeave ( to, from, next ) {
    this.before_leave_guard( next );
  }
}
</script>


<style lang='scss'>
.order-details {
  position: relative;
  padding: 1.5rem;
  flex: 1 1 0;
  overflow-y: auto;
  .h2 {
    margin: 0;
  }
  .details-top-row {
    --gutter-y: 1rem;
    justify-content: space-between;
  }
  .order-actions {
    // --gutter-x: 0.5rem;
    --gutter-y: 0.5rem;
    & .btn.btn-link {
      padding: 0;
      color: var( --secondary ) !important;
      &:hover,
      &:focus {
        color: var( --primary ) !important;
      }
      .svg-inline--fa,
      .spinner-border {
        margin-right: 0.5em;
      }
    }
  }
  .details-subsections {
    overflow: hidden;
    .grid {
      --border-thickness: 1px;
      --item-padding-y: 20px;
      --item-padding-x: 30px;

      display: grid;
      grid-template-areas: 
        'customer addresses'
        'shipping shipping';
      grid-template-columns: 1fr 2fr;

      &.notes {
        grid-template-areas: 
          'customer addresses'
          'shipping shipping'
          'cnote cnote';
      }

      .customer {
        grid-area: customer;
      }
      .addresses {
        grid-area: addresses;
      }
      .shipping {
        grid-area: shipping;
      }
      .customer-note {
        grid-area: cnote;
      }

      margin: 0 calc( var( --item-padding-x ) * -1 );
      grid-gap: var( --border-thickness );
      background-color: var( --bs-border-color );
      border-top: var( --border-thickness ) solid transparent;
      border-bottom: var( --border-thickness ) solid transparent;
      & > * {
        padding: var( --item-padding-y ) var( --item-padding-x );
        background-color: white;
        & > *:not(:first-child) {
          margin-top: var( --item-padding-y );
        }
      }
    }
    h3 {
      margin-bottom: 0.25em;
    }
  }
  .order-items {
    margin-top: 2rem;
  }
  .order-totals {
    margin-top: 2rem;
  }
}
</style>