<template>
  <div class='button-carousel'>
    <button class='nav-btn prev' @click='set_position( position - 1 )' :disabled='position == 0'>
      <span class="sr-only">Previous</span>
      <font-awesome-icon icon='fa-solid fa-chevron-left' />
    </button>
    <div class='content-wrap'>
      <div class='content' :style='{ width: content_width, transform: content_transform, transitionDuration: `${ speed }ms` }'>
        <button
          v-for='option, i in options' :key='`carousel-option-${ i }`'
          @click='select_option( option )'
          :class='{ selected: option.key == active }'
          >
          <span>{{ option.label }}</span>
        </button>
      </div>
    </div>
    <button class='nav-btn next' @click='set_position( position + 1 )' :disabled='position == max_position'>
      <span class="sr-only">Next</span>
      <font-awesome-icon icon='fa-solid fa-chevron-right' />
    </button>
  </div>
</template>


<script>
export default {
  name: 'ButtonCarousel',
  props: {
    options: {
      type: Array, // of { label, key, ... }
      required: true,
    },
    count: {
      type: Number,
      default: 5,
    },
    active: {
      type: String,
      default: null,
    },
    speed: {
      type: Number,
      default: 300,
    },
  },
  data () {
    return {
      position: 0,
    }
  },
  computed: {
    max_position () {
      return Math.max( 0, this.options.length - this.count );
    },
    content_width () {
      return `${ this.options.length / this.count * 100 }%`;
    },
    content_transform () {
      return `translateX( -${ this.position / this.options.length * 100 }% )`;
    },
  },
  methods: {

    set_position ( new_position ) {
      this.position = Math.max( 0, Math.min( new_position, this.max_position ) );
    },

    select_option ( option ) {
      this.$emit( 'selected', option.key );
    },

    set_default_position () {
      let active_index = this.options.findIndex( x => x.key == this.active );
      let offset = Math.floor( this.count / 2 ) + ( this.count % 2 ) - 1;
      this.set_position( active_index - offset );
    }

  },
  watch: {
    count: {
      immediate: true,
      handler: 'set_default_position',
    },
  },
}
</script>



<style lang="scss" scoped>
.button-carousel {
  padding: 0 20px;
  position: relative;
}
.nav-btn {
  position: absolute;
  top: 50%;
  transform: translateY( -50% );
  &.prev {
    left: 0;
  }
  &.next {
    right: 0;
  }
  border: 0;
  appearance: none;
  padding: 0;
  background-color: transparent;
  font-size: 1.5em;
  color: var( --dark );
  &[disabled] {
    opacity: 0.4;
  }
}
.content-wrap {
  border: 1px solid var( --bs-border-color );
  overflow: hidden;
  .content {
    display: flex;
    align-items: stretch;
    transition-property: transform;
    transition-timing-function: ease;
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 0;
    border: 0;
    appearance: none;
    padding: 1rem;
    font-size: 1em;
    transition: all 0.3s ease;
    background-color: white;
    color: var( --dark );
    &:hover,
    &:focus,
    &.selected {
      color: white;
      background-color: var( --blue );
    }
  }
}
</style>