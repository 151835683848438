<template>
  <b-button
    class='order-pdf-exporter'
    @click='download_pdf'
    variant=link
    :disabled='busy'
    :class='{ busy, exported }'
    >
    <font-awesome-icon v-if='busy' icon='fa-solid fa-spinner' class='fa-spin' />
    <font-awesome-icon v-else-if='exported' icon='fa-solid fa-check' />
    <font-awesome-icon v-else :icon='`fa-regular ${ icon }`' />
    <span>{{ type }} Slip</span>
  </b-button>
</template>

<script>
import axios from '@/axios';
import { mapState, mapMutations } from 'vuex';
import EventBus from '@/event-bus';

export default {
  name: 'OrderPdfExporter',
  props: {
    type: {
      validator: function (value) {
        return [ 'picking', 'packing' ].includes( value );
      },
    },
    action_block: {
      required: false,
      type: Function
    }
  },
  data () {
    return {
      busy: false,
    }
  },
  computed: {
    exported () {
      return Boolean( this.order[ `${ this.type }_slip_exported` ] ?? false );
    },
    ...mapState( 'order_details', [
      'order',
    ]),
    icon () {
      switch ( this.type ) {
        case 'picking':
          return 'fa-clipboard-list';
        case 'packing':
          return 'fa-truck';
      }
    }
  },
  methods: {
    ...mapMutations( 'order_details', [
      'set_order_prop',
    ]),
    download_pdf () {
      if ( ( this.action_block ?? null ) && this.action_block() ) {
        return;
      }
      this.busy = true;
      EventBus.$emit( `exporting-pdf/${ this.type }` );
      axios.get( `/orders/${ this.order.id }/pdfs/${ this.type }`, {
          responseType: 'blob',
        })
        .then( response => {
          const FILE = window.URL.createObjectURL (new Blob([ response.data ]) );
          const dl_link = document.createElement( 'a' );
          dl_link.href = FILE;
          dl_link.setAttribute( 'download', response.headers['content-disposition'].split('filename=')[1].split(';')[0] );
          document.body.appendChild( dl_link );
          dl_link.click();
          dl_link.remove();
          this.set_order_prop({
            prop: `${ this.type }_slip_exported`,
            value: true,
          });
        })
        .catch( () => {
          this.$jjToast({
            type: 'error',
            title: 'Error',
            content: `Failed to export ${ this.type } slip`,
          });
        })
        .finally( () => {
          this.busy = false;
        })
    },
  }
}
</script>


<style>
.order-pdf-exporter {
  text-transform:capitalize;
}
</style>