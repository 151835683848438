<template>
  <div :class='`edit-address ${ type }-address`'>
    <h3>
      {{ type | capitalize }} Address
      <edit-icon-btn  @click='$refs[`modal`].show()' />
    </h3>
    <div>
      <font-awesome-icon v-if='this.saved_address.address_verified' icon='fa-solid fa-check' />
      {{ formatted_address }}
    </div>

    <b-modal
      ref='modal'
      :class='`edit-address-modal ${ type }-address-modal`'
      centered
      :title='`${ type } Address` | capitalize'
      size=md
      ok-title='Continue'
      ok-variant='primary'
      cancel-variant='medium'
      @ok='update_address'
      @show='reset_address'
      :busy='busy'
      >
      <b-row v-if='pending_address' class='form-group-row'>
        <b-col cols=6>
          <b-form-group
            label='Address 1'
            :label-for='`${ type }-address-1`'
            >
              <b-form-input
                :id='`${ type }-address-1`'
                v-model='pending_address.address_1'
                />
          </b-form-group>
        </b-col>

        <b-col cols=6>
          <b-form-group
            label='Address 2'
            :label-for='`${ type }-address-2`'
            >
              <b-form-input
                :id='`${ type }-address-2`'
                v-model='pending_address.address_2'
                />
          </b-form-group>
        </b-col>

        <b-col cols=6>
          <b-form-group
            label='City'
            :label-for='`${ type }-city`'
            >
              <b-form-input
                :id='`${ type }-city`'
                v-model='pending_address.city'
                />
          </b-form-group>
        </b-col>

        <b-col cols=6>
          <b-form-group
            label='Province'
            :label-for='`${ type }-state`'
            >
              <b-form-select
                :id='`${ type }-state`'
                :options='province_list'
                v-model='pending_address.state'
                v-if="province_mode == 'select'"
                />
              <b-form-input
                :id='`${ type }-state`'
                v-model='pending_address.state'
                v-if="province_mode == 'text'"
                />
          </b-form-group>
        </b-col>

        <b-col cols=6>
          <b-form-group
            label='Postal Code'
            :label-for='`${ type }-postcode`'
            >
              <b-form-input
                :id='`${ type }-postcode`'
                v-model='pending_address.postcode'
                />
          </b-form-group>
        </b-col>

        <b-col cols=6>
          <b-form-group
            label='Country'
            :label-for='`${ type }-country`'
            >
              <b-form-select
                :id='`${ type }-country`'
                :options='country_list'
                v-model='pending_address.country'
                @change='update_province'
                />
          </b-form-group>
        </b-col>

        <b-col v-if='type == `billing`' cols=12>
          <b-form-checkbox
            id="copy-as-shipping"
            v-model="copy_to_shipping"
            name="copy-as-shipping"
            >
            Copy as shipping address
          </b-form-checkbox>
        </b-col>
        
        <b-col v-if='type == `shipping`' cols=12>
          <b-form-checkbox
            id="address-verified"
            v-model="pending_address.address_verified"
            name="address-verified"
            >
            Address Verified
          </b-form-checkbox>
        </b-col>

      </b-row>
    </b-modal>

  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';

import { mapState, mapMutations } from 'vuex';

export default {
  name: 'EditAddress',
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      pending_address: null,
      busy: false,
      copy_to_shipping: false,
      country_list: [],
      province_list: [],
      province_mode: 'select'
    }
  },
  computed: {

    ...mapState( 'order_details', [
      'order'
    ]),
    
    ...mapState( 'orders', [
      'countries'
    ]),

    saved_address () {
      return this.order[ this.type ] ?? {};
    },

    formatted_address () {
      const street_address = [ 'address_1', 'address_2' ].map( prop => this.saved_address[ prop ] ?? null ).filter( x => x ).join( ' ' );
      const three = [ 'state', 'postcode', 'country' ].map( prop => this.saved_address[ prop ] ?? null ).filter( x => x ).join( ' ' );
      const full = [ street_address, this.saved_address.city ?? null, three ].filter( x => x ).join( ', ' );
      return full ? full : '-';
    },

    saved_address_3 () {
      return [ this.saved_address.city ?? null, [ this.saved_address.state, this.saved_address.postcode ].filter( x => x ).join( ' ' ) ].filter( x => x ).join( ', ' );
    }

  },
  created () {
    this.country_list = this.countries.map( (item) => {
      return {
        value: item.code,
        text: item.name
      };
    });
  },
  filters: {
    capitalize ( string ) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
  methods: {

    ...mapMutations( 'order_details', [
      'set_order_prop',
    ]),

    reset_address () {
      this.pending_address = _.cloneDeep( this.saved_address );
      this.copy_to_shipping = false;

      if ( ! this.pending_address.country ) {
        this.pending_address.country = 'CA';
      }

      this.update_province();
    },

    update_province () {
      let country = this.countries.filter( (item) => {
        return item.code === this.pending_address.country;
      });

      if ( country.length && country[0].states.length ) {
        this.province_list = country[0].states.map( (item) => {
          return {
            value: item.code,
            text: item.name
          };
        });

        if ( ! this.province_list.find(o => o.value === this.pending_address.state) ) {
          this.pending_address.state = '';
        }
        
        if ( ! this.pending_address.state && this.pending_address.country == 'CA' ) {
          this.pending_address.state = 'ON';
        }

        this.province_mode = 'select';
      } else {
        this.province_mode = 'text';
      }

      
    },

    update_address ( e ) {
      e.preventDefault();
      this.busy = true;
      const busy_id = this.$jjToast({
        type: 'busy',
        title: 'Saving',
        content: `Saving ${ this.type } address`,
      });
      axios.put( `orders/${ this.order.id }/address/${ this.type }`, {
          address: this.pending_address,
          copy_to_shipping: this.copy_to_shipping,
        })
        .then( (response) => {
          this.set_order_prop({
            prop: this.type,
            value: this.pending_address,
          });
          if ( this.copy_to_shipping ) {
            this.set_order_prop({
              prop: 'shipping',
              value: this.pending_address,
            });
          }

          this.set_order_prop({
            prop: 'shipping_tax',
            value: response.data.order.shipping_tax,
          });

          this.set_order_prop({
            prop: 'total_tax',
            value: response.data.order.total_tax,
          });

          this.$refs['modal'].hide();
          this.$jjToast({
            type: 'success',
            title: 'Updated',
            content: `Saved ${ this.type } address`,
          });
        })
        .catch( () => {
          this.$jjToast({
            type: 'error',
            title: 'Error',
            content: `Failed to save ${ this.type } address`,
          });
        })
        .finally( () => {
          this.busy = false;
          this.$bvToast.hide( busy_id );
        });
    }

  },
}
</script>