<template>
  <div v-if='shipping_methods.length'>
    <h3>
      Shipping Details
      <edit-icon-btn  @click='$refs[`modal`].show()' />
    </h3>
    <b-row v-if='shipping_line_method'>
      <b-col cols=auto v-for='item, index in formatted' :key='`formatted-${ index }`'>{{ item }}</b-col>
    </b-row>

    <b-modal
      ref='modal'
      class='shipping-details-modal'
      centered
      title='Shipping Details'
      size=md
      ok-title='Continue'
      ok-variant='primary'
      cancel-variant='medium'
      @ok='handleOK'
      @show='reset_shipping_line'
      :busy='busy'
      >

      <b-row v-if='shipping_line' class='form-group-row'>
        <b-col cols=6>
          <b-form-group
            label='Type Of Shipping'
            label-for='shipping-name'
            >
              <vue-multiselect
                id='shipping-name'
                placeholder='Select an option'
                v-model='selected_method'
                :options='shipping_methods'
                label='label'
                track-by='key'
                :multiple='false'
                :searchable='false'
                :allow-empty='false'
                :showLabels='false'
                />
          </b-form-group>
        </b-col>

        <b-col cols=6>
          <b-form-group
            label='Shipping Amount'
            label-for='shipping-amount'
            >
            <FixedDecimalNumberInput id='shipping-amount' :value='shipping_line.total' @input='value => shipping_line.total = value' />
          </b-form-group>
        </b-col>

        <template v-if='shipping_line.method_id != `local_pickup`'>
          <b-col cols=6>
            <b-form-group
              label='Tracking Number'
              label-for='tracking-number'
              >
                <b-form-input
                  id='tracking-number'
                  :value='pending_meta( `tracking_number` )'
                  @input='update_meta( `tracking_number`, $event )'
                  />
            </b-form-group>
          </b-col>

          <b-col cols=6>
            <b-form-group
              label='Courier Name'
              label-for='courier-name'
              >
                <b-form-input
                  id='courier-name'
                  :value='pending_meta( `courier_name` )'
                  @input='update_meta( `courier_name`, $event )'
                  />
            </b-form-group>
          </b-col>
        </template>

        <b-col cols=6>
          <b-form-group
            label='Weight'
            label-for='weight'
            >
              <b-form-input
                id='weight'
                :value='pending_meta( `weight` )'
                @input='update_meta( `weight`, $event )'
                />
          </b-form-group>
        </b-col>

        <b-col cols=6>
          <b-form-group
            label='Number of Boxes/Pieces'
            label-for='num-boxes'
            >
              <b-form-input
                id='num-boxes'
                :value='pending_meta( `num_boxes` )'
                @input='update_meta( `num_boxes`, $event )'
                type='number'
                />
          </b-form-group>
        </b-col>

        <b-col cols=12>
          <b-form-group
            label='Payment Note'
            label-for='payment_note'
            >
              <b-form-select
                id='payment_note'
                :options='payment_note'
                :value='pending_meta( `payment_note` )'
                @input='update_meta( `payment_note`, $event )'
                />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>


  </div>
</template>

<script>
import _ from 'lodash';
import axios from '@/axios';
import EventBus from '@/event-bus';
import FixedDecimalNumberInput from '@/components/fixed-decimal-number-input';

import { mapState, mapMutations } from 'vuex';

export default {
  name: 'ShippingDetails',
  components: {
    FixedDecimalNumberInput,
  },
  data () {
    return {
      shipping_line: null,
      busy: false,
      payment_note: [
        '',
        'Shipping - Invoice & Pay Later (on account)',
        'Shipping - Pay Before Delivery with Rec in Box',
        'Pick Up - Invoice & Pay Later (on account)',
        'Pick Up - Pay at Pick Up (COD)',
        'Pick Up - Pay Before Pick Up (Rec in Box)'
      ]
    }
  },
  computed: {

    selected_method: {
      get () {
        return this.shipping_methods.find( option => option.key === this.shipping_line.method_id );
      },
      set ( value ) {
        this.shipping_line.method_id = value.key;
      }
    },

    ...mapState( 'orders', [
      'shipping_methods'
    ]),

    ...mapState( 'order_details', [
      'order',
    ]),

    order_shipping_line () {
      return Object.values( this.order.shipping_lines )[0];
    },

    // values from the root order object
    saved_meta () {
      return ( key ) => {
        const meta = this.order_shipping_line.meta_data.find( x => x.key == key );
        return meta ? meta.value : '';
      }
    },

    // cloned values, not pushed to the order yet
    pending_meta () {
      return ( key ) => {
        const meta = this.shipping_line.meta_data.find( x => x.key == key );
        return meta ? meta.value : '';
      }
    },

    shipping_line_method () {
      return this.shipping_methods.find( x => this.order_shipping_line.method_id == x.key );
    },

    formatted () {
      const num_boxes = parseInt( this.saved_meta( 'num_boxes' ) );
      const boxes = num_boxes ? ( num_boxes > 1 ? `${ num_boxes } boxes` : `${ num_boxes } box` ) : null;
      return [
        this.shipping_line_method.label,
        this.saved_meta( 'courier_name' ),
        this.saved_meta( 'tracking_number' ),
        this.saved_meta( 'weight' ),
        boxes,
        this.$options.filters.money_format( this.order_shipping_line.total ),
        this.saved_meta( 'payment_note' ),
      ].filter( x => x );
    },
    
  },
  methods: {

    ...mapMutations( 'order_details', [
      'set_order_prop',
    ]),

    reset_shipping_line () {
      this.shipping_line = _.cloneDeep( this.order_shipping_line );
    },

    update_meta ( key, value ) {
      if ( this.shipping_line.meta_data ?? false ) {
        const index = this.shipping_line.meta_data.findIndex( x => x.key == key );
        const new_meta = this.shipping_line.meta_data[ index ] ?? {
          id: null,
          key,
        };
        new_meta.value = value;
        ( index >= 0 ) ? this.shipping_line.meta_data.splice( index, 1, new_meta ) : this.shipping_line.meta_data.push( new_meta );
      }
    },

    open_modal () {
      this.$refs['modal'].show();
    },

    handleOK ( e ) {
      e.preventDefault();
      this.busy = true;
      const busy_id = this.$jjToast({
        type: 'busy',
        title: 'Saving',
        content: 'Saving order shipping details',
      });
      axios.put( `orders/${ this.order.id }/shipping-method`, {
          shipping: this.shipping_line
        })
        .then( (response) => {
          this.set_order_prop({
            prop: `shipping_lines.${ this.shipping_line.id }`,
            value: this.shipping_line,
          });
          this.set_order_prop({
            prop: 'shipping_total',
            value: this.shipping_line.total ? parseFloat( this.shipping_line.total ) : 0,
          });
          this.set_order_prop({
            prop: 'total',
            value: response.data.order.total,
          });
          this.set_order_prop({
            prop: 'total_tax',
            value: response.data.order.total_tax,
          });
          this.set_order_prop({
            prop: 'discount_total',
            value: response.data.order.discount_total,
          });
          this.set_order_prop({
            prop: 'discount_percentage',
            value: response.data.order.discount_percentage,
          });
          this.set_order_prop({
            prop: 'payment_note',
            value: response.data.order.payment_note,
          });
          EventBus.$emit( 'order-shipping-confirmed' );
          this.$refs['modal'].hide();
          this.$jjToast({
            type: 'success',
            title: 'Updated',
            content: 'Saved order shipping details',
          });
        })
        .catch( () => {
          this.$jjToast({
            type: 'error',
            title: 'Error',
            content: 'Failed to save order shipping details',
          });
        })
        .finally( () => {
          this.busy = false;
          this.$bvToast.hide( busy_id );
        })
    }


  },
  created () {
    EventBus.$on( 'open-edit-shipping-modal', this.open_modal );
  },
  beforeDestroy () {
    EventBus.$off( 'open-edit-shipping-modal', this.open_modal );
  },
}
</script>