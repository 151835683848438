<template>
  <div class='order-status-updater'>

    <ButtonCarousel
      :options='statuses'
      :active='order.status'
      @selected='set_status'
      :class='{ disabled: busy }'
      :count='slide_count'
      />

    <jj-loading v-if='busy' centered />

    <b-modal
      ref='modal'
      class='confirm-status-modal'
      centered
      title='Update Order Status'
      size=md
      ok-title='Continue'
      ok-variant='primary'
      cancel-variant='medium'
      @ok='handle_update_request'
      >
      <p v-if='new_status'>Update status of order #{{ order.id }} to {{ new_status.label }}?</p>
    </b-modal>

  </div>
</template>

<script>
import axios from '@/axios';
import { mapState } from 'vuex';
import EventBus from '@/event-bus';

import ButtonCarousel from '@/components/carousel';

export default {
  name: 'OrderStatusUpdater',
  components: {
    ButtonCarousel,
  },
  props: {
    action_block: {
      required: false,
      type: Function
    }
  },
  data () {
    return {
      new_status_key: null,
      busy: false,
      must_confirm_shipping: false,
      slide_count: 5,
    }
  },
  computed: {

    ...mapState( 'orders', [
      'statuses'
    ]),

    ...mapState( 'order_details', [
      'order'
    ]),

    new_status () {
      return this.statuses.find( x => x.key == this.new_status_key );
    },

  },
  methods: {

    // manually set next status
    set_status ( key ) {
      if ( key != this.order.status ) {
        if ( ( this.action_block ?? null ) && this.action_block() ) {
          return;
        }
        this.new_status_key = key;
        this.must_confirm_shipping = ( key == 'wc-ready-to-ship' );
        EventBus[ this.must_confirm_shipping ? '$on' : '$off' ]( 'order-shipping-confirmed', this.update_status );
        this.$refs.modal.show();
      }
    },

    handle_update_request () {
      if ( this.must_confirm_shipping ) {
        EventBus.$emit( 'open-edit-shipping-modal' );
      } else {
        this.update_status();
      }
    },

    async update_status () {
      this.busy = true;
      const busy_id = this.$jjToast({
        type: 'busy',
        title: 'Saving',
        content: 'Saving order status',
      });
      let response = await axios.put( `orders/${ this.order.id }/status`, {
        status: this.new_status_key,
      });
      if ( response.status == 200 ) {
        this.$refs.modal.hide();
        EventBus.$emit( 'order-status-updated', {
          id: this.order.id,
          status: this.new_status_key,
        });
        this.$jjToast({
            type: 'success',
            title: 'Updated',
            content: 'Saved order status',
          });
      } else {
        this.$jjToast({
          type: 'error',
          title: 'Error',
          content: 'Failed to save order status',
        });
      }
      this.$nextTick( () => {
        this.busy = false;
        this.$bvToast.hide( busy_id );
        EventBus.$off( 'order-shipping-confirmed', this.update_status );
      });
    },


    on_resize: _.debounce( function () { this.sync_on_resize() }, 100 ),
    sync_on_resize () {
      this.slide_count = ( window.innerWidth < 992 ) ? 3 : ( ( window.innerWidth < 1200 ) ? 4 : 5 );
    },


    // automatically updates to packing without confirmation
    // used to trigger auto-update after picking-slip generated
    force_packing () {
      if ( this.order.status == 'wc-pending' || this.order.status == 'wc-processing' ) {
        this.new_status_key = 'wc-packing';
        this.update_status();
      }
    }

  },
  created () {
    EventBus.$on( 'exporting-pdf/picking', this.force_packing );
    window.addEventListener( 'resize', this.on_resize );
    this.sync_on_resize();
  },
  beforeDestroy () {
    EventBus.$off( 'exporting-pdf/picking', this.force_packing );
    EventBus.$off( 'order-shipping-confirmed', this.update_status );
    window.removeEventListener( 'resize', this.on_resize );
  }
}
</script>



<style lang='scss' scoped>
.order-status-updater {
  position: relative;
}
.button-carousel.disabled {
  pointer-events: none;
  opacity: 0.3;
}
</style>